import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-aum-report',
  templateUrl: './aum-report.component.html',
  styleUrl: './aum-report.component.scss'
})
export class AumReportComponent {
  @Input() jsonData: any;
  @Input() documentTitle: any;
  reportId: number = 0;
  pdfDoc: any;
  assetCount: number = 1;
  isProcessing: boolean = false;
  offsetDate: string = '';
  isDesktopDevice: boolean = true;
  isIOS: boolean = false;


  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
      this.epicFunction();
      this.isIOS = this.iOS();
      this.isProcessing = true;
  }

  epicFunction() {
      this.isDesktopDevice = this.deviceService.isDesktop();
  }

  iOS() {
      return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
      ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;


  ngAfterViewInit() {
      let backdrop = document.getElementsByClassName('paddingSlim') as HTMLCollectionOf<Element>;
      backdrop[0]?.classList?.add('centerAligned');
      backdrop[0]?.classList?.remove('paddingSlim');
      let self = this;
      self.prepareDoc();
  }

  prepareDoc() {
      let self = this;
      setTimeout(() => {
          let backdrop = document.getElementsByClassName('content-area') as HTMLCollectionOf<Element>;
          if (!this.isDesktopDevice)
              backdrop[0]?.classList?.add('zoomOut');
          self.isProcessing = false;
          if (!this.isIOS)
              this.loadPagedJsPolyfill();
      }, 3000);
  }

  loadPagedJsPolyfill() {
      const node = document.createElement('script');
      node.src = '../../../../assets/js/paged.pollyfil.js';
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
      setTimeout(() => {
          this.addPrintButton();
      }, 8000);
  }

  print() {
      this.isProcessing = false;
      let self = this;
      setTimeout(() => {
          document.title = self.documentTitle;
          window.print();
      }, 5000)

  }

  addPrintButton() {
      document.title = this.documentTitle;
      let div = document.getElementsByClassName('pagedjs_pages') as HTMLCollectionOf<Element>;
      let el = `<div class="noprint print-banner  print-button desktop-only" style="
  position: absolute;
  right: 0;
  width: 100%;
">
  <span class="pe-5 float-end py-2" role="button"><i class="fa fa-print fa-2x"></i></span>
</div>
`
      let element = document.createElement('div');
      element.classList.add('noprint');
      element.style.height = '57px';
      element.innerHTML = el;
      div[0]?.prepend(element);
      let printButtonEl = document.getElementsByClassName('print-button') as HTMLCollectionOf<Element>;
      printButtonEl[0]?.addEventListener('click', (e) => {
          window.print();
      });
  }

  getCurrentData(data: any) {
      if (data?.data.length) {
          const current = data?.data.filter(item => item.name === 'Current');
          return { ...data, data: current };
      } else return data;
  }

  customizeColumnChartDate(data: any) {
      if (data?.data.length) {
          let totalCounts = [];
          const counts = data?.data.map(item => { totalCounts.push(0); return item.count });
          counts.forEach(element => {
              element.forEach((ele, ind) => {
                  totalCounts[ind] = Number.isNaN(ele + totalCounts[ind]) ? 0 : ele + totalCounts[ind];
              });
          });
          const total = totalCounts.reduce((a, b) => a + b, 0);
          const totalOnePer = Math.round(total * 0.01);
          const dataa = data.data.map((element) => {
              let count = [];
              let value = [];
              element.count.forEach((cnt, index) => {
                  const onePer = Math.round(totalCounts[index] * 0.01);
                  const customCount = cnt == 0 ? 0 : (cnt + onePer) < totalOnePer ? totalOnePer : cnt < onePer ? onePer : cnt;
                  count.push(customCount);
                  value.push(cnt);
              });
              return { ...element, count: count, value: value }
          });
          return { ...data, data: dataa }
      }
      return data;
  }
}
