<div class="noprint backdrop" *ngIf="isProcessing">
    <!-- <i
        class="fas fa-spinner fa-pulse fa-3x healthy"
        style="margin-top: 10%; margin-left: 47%"
    ></i> -->
    Loading...
</div>

<div class="no-preview-print print-banner first-print-button" *ngIf="isIOS">
    <span class="pe-5 float-end py-2" (click)="print()" role="button"
        ><i class="fa fa-print fa-2x"></i
    ></span>
</div>

<div class="page-header" style="text-align: center">
    <!-- I'm The Header -->
    <br />
</div>

<div class="page-footer">
    <span> Document Ref:{{ jsonData?.documentRef }} </span>
</div>

<table>
    <thead>
        <tr>
            <td>
                <!--place holder for the fixed-position header-->
                <!-- <div class="page-header-space"></div> -->
            </td>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
                <div
                    id="pdfTable"
                    #pdfTable
                    style="width: 650px"
                    class="pdfTable"
                >
                    <div
                        class="container position-relative pdf-page"
                        id="cover-page"
                    >
                        <div class="header row">
                            <img
                                src="../../../../assets/images/reports/header.png"
                                alt=""
                                class="w-100"
                            />
                        </div>
                        <div
                            class="title w-100 pe-5"
                            style="margin-top: 200px; height: 185px"
                        >
                            <img
                                src="../../../../assets/images/newLogo/predict_dark.png"
                                alt=""
                                class="float-end"
                                height="100"
                            />
                        </div>
                        <div class="text-center report-title">AuM REPORT</div>
                        <div
                            class="details mt-3 w-100 p-3"
                            style="font-size: 18px"
                        >
                            <div class="details-data w-100">
                                <span class="field-name">Report Date:</span>
                                <span class="field-value">{{
                                    jsonData?.reportDate
                                        | date
                                            : "dd/MM/yyyy h:mm:ssa"
                                            : offsetDate
                                }}</span>
                            </div>
                            <div class="details-data">
                                <span class="field-name">Version:</span>
                                <span class="field-value">{{
                                    jsonData?.version
                                }}</span>
                            </div>
                            <div class="details-data">
                                <span class="field-name">Document Ref:</span>
                                <span class="field-value"
                                    >{{ jsonData?.documentRef }}
                                </span>
                            </div>
                            <div class="details-data">
                                <span class="field-name"
                                    >Confidentiality details:</span
                                >
                                <span class="field-value">{{
                                    jsonData?.confidentialDetails
                                }}</span>
                            </div>
                        </div>
                        <div class="footer w-100 mt-5">
                            <img
                                src="../../../../assets/images/lntlogo.png"
                                alt=""
                                class="float-end"
                                height="55"
                            />
                        </div>
                    </div>

                    <div class="container pdf-page">
                        <div class="section-content">
                            <p>
                                This report provides the details of assets under
                                management and asset under maintenance.
                            </p>
                            <p>
                                By internal definition, any active hardware,
                                software or system installed in the customer
                                site, with a continuous or discontinuous
                                connection to a server, cloud etc , managed by
                                or accessible to L&T E&A. The assets can be from
                                any manufacturers, not only from L&T E&A /
                                SEIPL.
                            </p>
                            <p>
                                Example : a contactor, a breaker, a transformer,
                                a meter etc ( falls under component category as
                                per Smartcomm predict definition)
                            </p>
                        </div>

                        <div class="summary">
                            <div class="section-heading title-text">
                                <span class="text-underline" id="summary">
                                    Summary</span
                                >
                            </div>
                            <div
                                class="section-content m-3"
                                style="display: flex; flex-wrap: wrap"
                            >
                                <div
                                    class=""
                                    *ngFor="
                                        let item of jsonData?.summary;
                                        let i = index
                                    "
                                    style="min-width: 33%"
                                >
                                    <div class="column-flex">
                                        <div class="">{{ item.name }}</div>
                                        <div class="font-count">
                                            {{ item.count }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="assetUnderManagement">
                            <p class="mb-0">
                                Asset under maintenance are those assets which
                                are in active maintenance contract ARC/AMC with
                                L&T E&A.
                            </p>
                            <div class="section-heading title-text">
                                <span class="text-underline" id="AUM">
                                    Asset Under Management (L&T E&A
                                    Assets)</span
                                >
                            </div>
                            <div class="section-content m-3">
                                <div style="display: flex; flex-wrap: wrap">
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Date & Time
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.management
                                                        ?.generatedOn
                                                        | date
                                                            : "dd/MM/yyyy h:mm:ssa"
                                                            : offsetDate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Initiated By
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.management
                                                        ?.initiatedBy
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Total Aum (Asset)
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.management
                                                        ?.totalAumAsset
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Total Aum (Component)
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.management
                                                        ?.totalAumComponent
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="column3-border"
                                        *ngFor="
                                            let item of jsonData?.management
                                                ?.Components;
                                            let i = index
                                        "
                                        style="min-width: 33%"
                                    >
                                        <div class="column-flex">
                                            <div class="font-name">
                                                {{ item.name }}
                                            </div>
                                            <div class="font-count">
                                                {{ item.count }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container pdf-page">
                        <div class="section-content">
                            <div>
                                <div id="ytdprogress">
                                    <div
                                        id="stackedchart-managementYTD"
                                        *ngIf="jsonData?.managementYTD"
                                    >
                                        <p>
                                            <span class="">YTD Progress</span>
                                        </p>
                                        <app-stacked-bar-chart
                                            #stackedchart
                                            [target]="
                                                'stackedchart-managementYTD'
                                            "
                                            [reportData]="
                                                customizeColumnChartDate(
                                                    jsonData?.managementYTD
                                                )
                                            "
                                        ></app-stacked-bar-chart>
                                        <!-- <app-linear-gradient-chart [target]="'asset-report-health-'+i" [data]="chartData?.healthIndex"
                      [params]="{assetId: 0, feeder: '', name: 'healthIndex'}"
                      [reportData]="item?.chartData"></app-linear-gradient-chart> -->
                                    </div>
                                </div>
                                <p>
                                    --------------------------------------------------------------------------------------------------------------
                                </p>

                                <div id="y-yprogress">
                                    <p>
                                        <span class="">Y-Y Progress</span>
                                    </p>
                                    <div
                                        id="stackedchart-managementYY"
                                        *ngIf="jsonData?.managementYY"
                                    >
                                        <app-stacked-bar-chart
                                            [target]="
                                                'stackedchart-managementYY'
                                            "
                                            [reportData]="
                                                customizeColumnChartDate(
                                                    jsonData?.managementYY
                                                )
                                            "
                                        ></app-stacked-bar-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container pdf-page">
                        <div class="assetUnderMaintenance">
                            <div class="section-heading title-text">
                                <span class="text-underline" id="AUM">
                                    Asset Under Maintenance</span
                                >
                            </div>
                            <div class="section-content m-3">
                                <div class="flex-wrap">
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Date & Time
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.maintenance
                                                        ?.generatedOn
                                                        | date
                                                            : "dd/MM/yyyy h:mm:ssa"
                                                            : offsetDate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Initiated By
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.maintenance
                                                        ?.initiatedBy
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Total Aum (Asset)
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.maintenance
                                                        ?.totalAumAsset
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Total Aum (Component)
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.maintenance
                                                        ?.totalAumComponent
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Customers
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.maintenance
                                                        ?.customers
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="column3-border"
                                        id="categories"
                                        *ngFor="
                                            let item of jsonData?.maintenance
                                                ?.Categories;
                                            let i = index
                                        "
                                    >
                                        <div class="column-flex">
                                            <div class="font-name">
                                                {{ item.name }}
                                            </div>
                                            <div class="font-count">
                                                {{ item.count }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="column3-border"
                                        id="contracts"
                                        *ngFor="
                                            let item of jsonData?.maintenance
                                                ?.Contracts;
                                            let i = index
                                        "
                                    >
                                        <div class="column-flex">
                                            <div class="font-name">
                                                {{ item.name }}
                                            </div>
                                            <div class="font-count">
                                                {{ item.count }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="column3-border"
                                        id="components"
                                        *ngFor="
                                            let item of jsonData?.maintenance
                                                ?.Components;
                                            let i = index
                                        "
                                    >
                                        <div class="column-flex">
                                            <div class="font-name">
                                                {{ item.name }}
                                            </div>
                                            <div class="font-count">
                                                {{ item.count }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container pdf-page">
                        <div class="section-content">
                            <div>
                                <div id="ytdprogress">
                                    <div id="stackedchart-maintenanceYTD">
                                        <p>
                                            <span class="">YTD Progress</span>
                                        </p>
                                        <app-stacked-bar-chart
                                            [target]="
                                                'stackedchart-maintenanceYTD'
                                            "
                                            [reportData]="
                                                getCurrentData(
                                                    jsonData?.maintenanceYTD
                                                )
                                            "
                                        ></app-stacked-bar-chart>
                                    </div>
                                </div>
                                <p>
                                    --------------------------------------------------------------------------------------------------------------
                                </p>
                                <div id="y-yprogress">
                                    <p>
                                        <span class="">Y-Y Progress</span>
                                    </p>
                                    <div id="stackedchart-maintenanceYY">
                                        <app-stacked-bar-chart
                                            [target]="
                                                'stackedchart-maintenanceYY'
                                            "
                                            [reportData]="
                                                getCurrentData(
                                                    jsonData?.maintenanceYY
                                                )
                                            "
                                        ></app-stacked-bar-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="container pdf-page"
                        *ngIf="jsonData?.managementForNonSe"
                    >
                        <div class="assetUnderManagement">
                            <p class="mb-0">
                                Other assets are those assets which are
                                onboarded on SmartComm Predict but are not
                                supplied by L&T E&A.
                            </p>
                            <div class="section-heading title-text">
                                <span class="text-underline" id="AUM">
                                    Other Assets</span
                                >
                            </div>
                            <div class="section-content m-3">
                                <div style="display: flex; flex-wrap: wrap">
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Date & Time
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.managementForNonSe
                                                        ?.generatedOn
                                                        | date
                                                            : "dd/MM/yyyy h:mm:ssa"
                                                            : offsetDate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Initiated By
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.managementForNonSe
                                                        ?.initiatedBy
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Total Aum (Asset)
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.managementForNonSe
                                                        ?.totalAumAsset
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Total Aum (Component)
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData?.managementForNonSe
                                                        ?.totalAumComponent
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="column3-border"
                                        *ngFor="
                                            let item of jsonData
                                                ?.managementForNonSe
                                                ?.Components;
                                            let i = index
                                        "
                                        style="min-width: 33%"
                                    >
                                        <div class="column-flex">
                                            <div class="font-name">
                                                {{ item.name }}
                                            </div>
                                            <div class="font-count">
                                                {{ item.count }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="container pdf-page"
                        *ngIf="
                            jsonData?.managementYTDForNonSe ||
                            jsonData?.managementYYForNonSe
                        "
                    >
                        <div class="section-content">
                            <div>
                                <div id="ytdprogress">
                                    <div
                                        id="stackedchart-managementYTDForNonSe"
                                        *ngIf="jsonData?.managementYTDForNonSe"
                                    >
                                        <p>
                                            <span class="">YTD Progress</span>
                                        </p>
                                        <app-stacked-bar-chart
                                            #stackedchart
                                            [target]="
                                                'stackedchart-managementYTDForNonSe'
                                            "
                                            [reportData]="
                                                customizeColumnChartDate(
                                                    jsonData?.managementYTDForNonSe
                                                )
                                            "
                                        ></app-stacked-bar-chart>
                                        <!-- <app-linear-gradient-chart [target]="'asset-report-health-'+i" [data]="chartData?.healthIndex"
                      [params]="{assetId: 0, feeder: '', name: 'healthIndex'}"
                      [reportData]="item?.chartData"></app-linear-gradient-chart> -->
                                    </div>
                                </div>
                                <p *ngIf="jsonData?.managementYYForNonSe">
                                    --------------------------------------------------------------------------------------------------------------
                                </p>

                                <div
                                    id="y-yprogress"
                                    *ngIf="jsonData?.managementYYForNonSe"
                                >
                                    <p>
                                        <span class="">Y-Y Progress</span>
                                    </p>
                                    <div
                                        id="stackedchart-managementYYForNonSe"
                                        *ngIf="jsonData?.managementYYForNonSe"
                                    >
                                        <app-stacked-bar-chart
                                            [target]="
                                                'stackedchart-managementYYForNonSe'
                                            "
                                            [reportData]="
                                                customizeColumnChartDate(
                                                    jsonData?.managementYYForNonSe
                                                )
                                            "
                                        ></app-stacked-bar-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="container pdf-page"
                        *ngIf="jsonData?.maintenanceForNonSe"
                    >
                        <div class="assetUnderMaintenance">
                            <div class="section-heading title-text">
                                <span class="text-underline" id="AUM">
                                    Asset Under Maintenance</span
                                >
                            </div>
                            <div class="section-content m-3">
                                <div class="flex-wrap">
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Date & Time
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData
                                                        ?.maintenanceForNonSe
                                                        ?.generatedOn
                                                        | date
                                                            : "dd/MM/yyyy h:mm:ssa"
                                                            : offsetDate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Initiated By
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData
                                                        ?.maintenanceForNonSe
                                                        ?.initiatedBy
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Total Aum (Asset)
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData
                                                        ?.maintenanceForNonSe
                                                        ?.totalAumAsset
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Total Aum (Component)
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData
                                                        ?.maintenanceForNonSe
                                                        ?.totalAumComponent
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column3-border">
                                        <div class="column-flex">
                                            <div class="font-name">
                                                Customers
                                            </div>
                                            <div class="font-count">
                                                {{
                                                    jsonData
                                                        ?.maintenanceForNonSe
                                                        ?.customers
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="column3-border"
                                        id="categories"
                                        *ngFor="
                                            let item of jsonData
                                                ?.maintenanceForNonSe
                                                ?.Categories;
                                            let i = index
                                        "
                                    >
                                        <div class="column-flex">
                                            <div class="font-name">
                                                {{ item.name }}
                                            </div>
                                            <div class="font-count">
                                                {{ item.count }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="column3-border"
                                        id="contracts"
                                        *ngFor="
                                            let item of jsonData
                                                ?.maintenanceForNonSe
                                                ?.Contracts;
                                            let i = index
                                        "
                                    >
                                        <div class="column-flex">
                                            <div class="font-name">
                                                {{ item.name }}
                                            </div>
                                            <div class="font-count">
                                                {{ item.count }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="column3-border"
                                        id="components"
                                        *ngFor="
                                            let item of jsonData
                                                ?.maintenanceForNonSe
                                                ?.Components;
                                            let i = index
                                        "
                                    >
                                        <div class="column-flex">
                                            <div class="font-name">
                                                {{ item.name }}
                                            </div>
                                            <div class="font-count">
                                                {{ item.count }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="container pdf-page"
                        *ngIf="
                            jsonData?.maintenanceYTDForNonSe ||
                            jsonData?.maintenanceYYForNonSe
                        "
                    >
                        <div class="section-content">
                            <div>
                                <div
                                    id="ytdprogress"
                                    *ngIf="jsonData?.maintenanceYTDForNonSe"
                                >
                                    <div
                                        id="stackedchart-maintenanceYTDForNonSe"
                                        *ngIf="jsonData?.maintenanceYTDForNonSe"
                                    >
                                        <p>
                                            <span class="">YTD Progress</span>
                                        </p>
                                        <app-stacked-bar-chart
                                            [target]="
                                                'stackedchart-maintenanceYTDForNonSe'
                                            "
                                            [reportData]="
                                                getCurrentData(
                                                    jsonData?.maintenanceYTDForNonSe
                                                )
                                            "
                                        ></app-stacked-bar-chart>
                                    </div>
                                </div>
                                <p *ngIf="jsonData?.maintenanceYYForNonSe">
                                    --------------------------------------------------------------------------------------------------------------
                                </p>
                                <div
                                    id="y-yprogress"
                                    *ngIf="jsonData?.maintenanceYYForNonSe"
                                >
                                    <p>
                                        <span class="">Y-Y Progress</span>
                                    </p>
                                    <div
                                        id="stackedchart-maintenanceYYForNonSe"
                                        *ngIf="jsonData?.maintenanceYYForNonSe"
                                    >
                                        <app-stacked-bar-chart
                                            [target]="
                                                'stackedchart-maintenanceYYForNonSe'
                                            "
                                            [reportData]="
                                                getCurrentData(
                                                    jsonData?.maintenanceYYForNonSe
                                                )
                                            "
                                        ></app-stacked-bar-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
            </td>
        </tr>
    </tfoot>
</table>
