import { Component, Input } from '@angular/core';
import { isNumber } from 'lodash';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrl: './report-table.component.scss'
})
export class ReportTableComponent {
  @Input() data: any[] = [];
  tableCellWidth: number;
  fields: any;
  constructor() { }

  ngOnInit(): void {
    if(this.data.length){
      const keys = Object.keys(this.data[0]);
      this.fields = keys
      this.tableCellWidth = 100 / keys.length;
    }
  }

  isDate(val: any) {
    if(isNumber(val)) return false;
    let timestamp = Date.parse(val);
    if (isNaN(timestamp)) {
      return false;
    } else {
      return true;
    }
  }

}
