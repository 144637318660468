<table class="table table-sm" style="height: fit-content;">
    <tr *ngIf="data" class="headStyle">           
      <td *ngFor="let head of fields" [width]="tableCellWidth + '%'">{{head | titlecase}}</td>
    </tr>
    <tr *ngFor="let item of data">           
      <td *ngFor="let field of fields" [width]="tableCellWidth + '%'">
        {{isDate(item[field]) ? (item[field] | date: 'dd/MM/yyyy') : (item[field])}}
      </td>
    </tr>
</table>
