import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import Highcharts, { Options } from 'highcharts';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from '../../../../../../shared/services/shared.service';

@Component({
  selector: 'app-linear-gradient-chart',
  templateUrl: './linear-gradient-chart.component.html',
  styleUrl: './linear-gradient-chart.component.scss'
})
export class LinearGradientChartComponent {
  chart: any;
	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Options = {};
	@Input() data: any;
	@Input() target: string = '';
	@Input() params!: any;
	@Input() forecastData!: any;
	@Input() isHealthIndex: boolean = false;

	range: any = [];
	series: any;
	yAxis: any;
	colors: any;
	durations: any = [];
	@Input() selectedDuration: number = 1;
	offsetDate!: string;
	dateRange: { max: any, min: any } = {
	max: '',
	min: ''
	};
	isloading: boolean = false;
	shortTermSeries: any;
	longTermSeries: any;
	midTermSeries: any;
	staticChartData: any;
	dynamicChartData: any;
	staticForecastData: any;
	healthScoreData: any;
	chartSeries: any;
	forecastFlag: boolean = false;
    shortHealthScore: any;
    prevHealthScore: any;

	constructor(
		private sharedService: SharedService,
		private deviceService: DeviceDetectorService
	) { }

	datasource: any;

	@Input() reportData: any;
	@Input() reportDuration!: any;

	forecastColors: any = {
	mid: '#9f41a1', 
	short: '#00a1e8', 
	long:'#b97b58', 
	static: '#7F7F7F', 
	dynamic: '#0000E6', 
	healthScore: '#ED1C24', 
	staticForecast: '#FFC90E'};
	selectedForecastTypes: any = [];
	selectedDataTypes: any = [];
	forecastTypes: any;
	isMobile: boolean;

    predictionTypes = [
        {name:"Daily",field:"daily",color:"#00a1e8",value:false},
        {name:"Weekly",field:"weekly",color:"#9f41a1",value:false},
        {name:"Monthly",field:"monthly",color:"#b97b58",value:false}
    ];

	ngOnInit(): void {
		this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
		if (this.reportData) {
			this.selectedDuration = 12;
			let startDate = this.reportDuration?.from;
			let endDate = this.reportDuration?.to;
			if (startDate) {
				this.dateRange.min = new Date(startDate).valueOf();
			}
			if (endDate) {
				this.dateRange.max = new Date(endDate).valueOf();
			}
		} else {
			this.dateRange.min = (new Date().setMonth(new Date().getMonth() - this.selectedDuration)).valueOf();
		}
		this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
		if (!this.target?.includes('report')) {
			this.getChartDurations();
		}
		this.getRange();
		this.fetchData();
	}

	fetchForecastTypes() {
		this.forecastTypes = [{name: 'short', duration: '', 'description':'Short Term'}, {name: 'mid', duration: '', 'description':'Mid Term'}, {name: 'long', duration: '', 'description':'Long Term'}];
		this.forecastTypes[0].duration = this.forecastData?.shortTerm + ' ' + this.forecastData.shortTermType;
		this.forecastTypes[1].duration = this.forecastData.midTerm + ' ' + this.forecastData.midTermType;
		this.forecastTypes[2].duration = this.forecastData.longTerm + ' ' + this.forecastData.longTermType;
	}

	getRange() {
		if (this.data && this.data.indexRange) {
			let keys = Object.keys(this.data?.indexRange);
			let arr: any = [];
			keys.forEach((key: string) => {
				arr.push({ name: key, value: this.data?.indexRange[key] });
			})
			this.range = arr;
		}
	}

	createChart() {
		this.isloading = false;
		let arr = this.range?.map((o: any) => o.name);
		let d1 = new Date();	
		let date = d1.valueOf();
		let self = this;
		(this.chartOptions as any) = {
			chart: {
				backgroundColor: '#fff',
				marginTop: 25,
				plotBackgroundColor: {
					linearGradient: [0, 0, 0, 350],
					stops: [
						[0, 'rgb(8 170 23 / 17%)'],
						[0.5, 'rgb(255 255 0 / 17%)'],
						[1, 'rgb(248 15 11 / 17%)']
					]
				},
				renderTo: 'container',
				type: 'line',
				zoomType: 'xy',
			},
			exporting: {
				enabled: false
			},
			legend: {
				enabled: false
			},
			series: [{
				color: '#f3c87c',
				data: this.series,
				marker: self.reportData? {enabled: true, radius: 2}: {enabled: false},
				dashStyle: 'ShortDash',
				lineWidth: 1,
				name: 'main',
				step: 'left',
				turboThreshold: 50000,
				zoneAxis: 'x',
				zones: [{
					value: date,
					color: '#707070',
					dashStyle: 'solid'
				}]
			},{
				color: self.forecastColors.static,
				data: this.staticChartData,
				marker: self.reportData? {enabled: true, radius: 2}: {enabled: false},
				dashStyle: 'solid',
				lineWidth: 2,
				name: 'static',
				step: 'left',
				turboThreshold: 50000,
				visible: this.selectedDataTypes.indexOf('static')!== -1,
				zoneAxis: 'x',
				zones: [{
					value: date,
					color: self.forecastColors.static,
					dashStyle: 'solid'
				}]
			},{
				color: self.forecastColors.dynamic,
				data: this.dynamicChartData,
				marker: self.reportData? {enabled: true, radius: 2}: {enabled: false},
				dashStyle: 'solid',
				lineWidth: 2,
				name: 'static',
				step: 'left',
				turboThreshold: 50000,
				visible: this.selectedDataTypes.indexOf('dynamic')!== -1,
				zoneAxis: 'x',
				zones: [{
					value: date,
					color: self.forecastColors.dynamic,
					dashStyle: 'solid'
				}]
			},{
				color: self.forecastColors.healthScore,
				data: this.healthScoreData,
				marker: self.reportData? {enabled: true, radius: 2}: {enabled: false},
				dashStyle: 'solid',
				lineWidth: 2,
				name: 'healthScore',
				// step: 'left',
				turboThreshold: 50000,
				visible: this.selectedDataTypes.indexOf('healthScore')!== -1,
				zoneAxis: 'x',
				zones: [{
					value: date,
					color: self.forecastColors.healthScore,
					dashStyle: 'solid'
				}]
			},{
				color: self.forecastColors.staticForecast,
				data: this.staticForecastData,
				marker: self.reportData? {enabled: true, radius: 2}: {enabled: false},
				dashStyle: 'solid',
				lineWidth: 2,
				name: 'staticForcast',
				step: 'left',
				turboThreshold: 50000,
				visible: this.selectedDataTypes.indexOf('staticForecast')!== -1,
				zoneAxis: 'x',
				zones: [{
					value: date,
					color: self.forecastColors.staticForecast,
					dashStyle: 'solid'
				}]
			},{
				color: self.forecastColors.short,
				data: this.shortTermSeries,
				marker: self.reportData? {enabled: true, radius: 2}: {enabled: false},
				dashStyle: 'solid',
				lineWidth: 2,
				name: 'short',
				step: 'left',
				turboThreshold: 50000,
				visible: this.selectedForecastTypes.indexOf('short')!== -1,
				zoneAxis: 'x',
				zones: [{
					value: date,
					color: self.forecastColors.short,
					dashStyle: 'solid'
				}]
			},{
				color: self.forecastColors.long,
				data: this.longTermSeries,
				marker: self.reportData? {enabled: true, radius: 2}: {enabled: false},
				dashStyle: 'solid',
				lineWidth: 2,
				name: 'long',
				step: 'left',
				turboThreshold: 50000,
				visible: this.selectedForecastTypes.indexOf('long')!== -1,
			},{
				color: self.forecastColors.mid,
				data: this.longTermSeries,
				marker: self.reportData? {enabled: true, radius: 2}: {enabled: false},
				dashStyle: 'solid',
				lineWidth: 2,
				name: 'mid',
				step: 'left',
				turboThreshold: 50000,
				visible: this.selectedForecastTypes.indexOf('mid')!== -1,
			}],
			time: {
				useUTC: false
			},
			title: {
				text: ''
			},
			tooltip: {
				enabled: true,
				xDateFormat: '%Y-%m-%d',
				formatter: function (): any {
					let date = new Date((this as any).x);
					let formattedDate = formatDate(new Date((this as any).x).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', self.offsetDate);
					const displayLabel = this.series.name === 'healthScore' ? ('HS' + (this as any).y) : ('HI' + self.healthScoreIndexPoint((this as any).y))
					return self.isHealthIndex ? (formattedDate + ':<b> ' + displayLabel + '</b>') : (formattedDate + ':<b> ' + arr[(this as any).y] + '</b>')
				},
				shared: false
			},
			xAxis: {
				crosshair: {
					color: '#0873F7',
					width: 0
				},
				endOnTick: false,
				labels: {
					format: '{value:%d.%m.%Y <br> %H:%M:%S}',
						style: {
							fontSize: '8px'
						}
				},
				lineColor: '#707070',
				tickColor: '#707070',
				max: self.reportData ? self.dateRange.max : undefined,
				min: self.dateRange.min,
				minRange: 1,
				minTickInterval: 60,
				plotLines: [{
					color: '#0F4874',
					label: {
						text: "▼",
						rotation: undefined,
						style: {
							"color": '#0F4874',
							"fontSize": "35px"
						},
						x: -14,
						y: 1
					},
					startOnTick: false,
					value: self.params?.name == 'healthIndex' ? date : undefined,
					width: 2,
					zIndex: 2
				}],
				startOnTick: false,
				tickPositioner: function (min: any, max: any) {
					var xDataRange = max - min,
					positions: any = [],
					tick = min, increment: any;
					if (xDataRange > 2592000000 && xDataRange < 4320000000) {
						// If range is between 1 month 50 days, make tick interval as 4 days
						increment = 345600000;
					}
					// Create ticks
					for (tick = min; tick - increment < max; tick += increment) {
						positions.push(tick);
					}
					return positions.length ? positions : undefined;
				},
				type: 'datetime'
			},
			yAxis: self.isHealthIndex ? [{
				tickPositions: [0, 4, 5.5, 6.5, 8, 11],
				// tickMarkPlacement: 'between',
				labels:{
					enabled: false
				},
				gridLineColor: '#CDCDCD',
				lineColor: {
					linearGradient: [0, 0, 0, 350],
					stops: [
						[0, 'rgb(8, 170, 23)'],
						[0.5, 'rgb(255, 255, 0)'],
						[1, 'rgb(248, 15, 11)']
					]
				},
				lineWidth: 15,
				max: 11,
				min: 0,
				offset: 10,
				reversed: true,
				title: {
					text: ''
				}
			},{
				title: {
					align: 'high',
					offset: 0,
					text: 'HI1',
					rotation: 0,
					x: 0,
					y: 70
				},
				offset: 17,
			},{
				title: {
					align: 'high',
					offset: 0,
					text: 'HI2',
					rotation: 0,
					x: 0,
					y: 150
				},
				offset: 17,
			},{
				title: {
					align: 'high',
					offset: 0,
					text: 'HI3',
					rotation: 0,
					x: 0,
					y: 185
				},
				offset: 17,
			},{
				title: {
					align: 'high',
					offset: 0,
					text: 'HI4',
					rotation: 0,
					x: 0,
					y: 220
				},
				offset: 17,
			},{
				title: {
					align: 'high',
					offset: 0,
					text: 'HI5',
					rotation: 0,
					x: 0,
					y: 285
				},
				offset: 17,
			}
			] : {
				categories: arr,
				gridLineColor: '#CDCDCD',
				lineColor: {
					linearGradient: [0, 0, 0, 350],
					stops: [
					[0, 'rgb(8, 170, 23)'],
					[0.5, 'rgb(255, 255, 0)'],
					[1, 'rgb(248, 15, 11)']
					]
				},
				lineWidth: 15,
				max: arr.length - 1,
				min: 0,
				offset: 10,
				reversed: true,
				title: {
					text: ''
				}
			}
		}
		let el = this.target;
		setTimeout(() => {
			self.chart = Highcharts.chart(el, self.chartOptions);
		}, 1000)
	}

	getData() {
		this.series = [];
		this.yAxis = [];
		let self = this;
		let tempSource = JSON.parse(JSON.stringify(this.datasource));
		tempSource.forEach((point: any) => {
			let y = point.healthIndex;
			point.healthIndex = 'HI' + point.healthIndex;
			point.criticalIndex = 'CI' + point.criticalIndex;
			point.riskIndex = 'RI' + point.riskIndex;
			point.maintenanceIndex = 'MI' + point.maintenanceIndex;
			let arr = this.range?.map((o: any) => o.name);
			if (this.params?.name == 'contactWear') {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.series.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: point.contactWear
				})
			} else if(this.params?.name == 'healthIndex'){
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.series.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: y
				})
			} else if (arr.indexOf(point[this.params?.name]) !== -1) {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.series.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: arr.indexOf(point[this.params?.name])
				})
			}
		});
		this.createChart();
	}

	fetchData(isFilter = false) {
		if (this.reportData) {
			this.datasource = this.reportData;
			this.isloading = false;
			this.getData();
		} else {
			// this.isloading = true;
			if(this.params?.name.includes('health') && this.forecastData) {
				this.fetchForecastTypes();
			}
			if(!isFilter) this.selectedDataTypes.push('static');
			this.fetchStaticDynamicData();
			// this.assetService.getChartData(this.params?.assetId, this.params?.feeder, this.selectedDuration).subscribe((chartData: any) => {
			//   this.datasource = chartData;
			// this.isloading = false;
			// this.getData();
			// });
		}
	}

	fetchStaticDynamicData() {
		this.isloading = true;
		let payload = {
			assetId: this.params?.assetId,
			feederNumber: this.params?.feeder,
			duration: this.selectedDuration,
			staticModel: this.selectedDataTypes.includes('static') ? true : false,
			dynamicModel: this.selectedDataTypes.includes('dynamic') || this.selectedDataTypes.includes('healthScore') ? true : false,
			staticForecast: this.selectedDataTypes.includes('staticForecast') ? true : false,
			healthScore: this.selectedDataTypes.includes('healthScore') ? true : false
		}
		// this.assetService.getStaticDynamicChartData(payload).subscribe((chartData: any) => {
		// 	if(chartData?.staticGraphData && payload.staticModel) this.getStaticData(chartData.staticGraphData);
		// 	if(chartData?.dynamicGraphData && payload.dynamicModel) this.getDynamicData(chartData.dynamicGraphData);
		// 	if(chartData?.staticForecastData && payload.staticForecast) this.getStaticForecastData(chartData.staticForecastData);
		// 	if(chartData?.staticGraphData && payload.healthScore) this.getHealthScoreData(chartData?.dynamicGraphData?.length ? chartData?.dynamicGraphData : chartData.staticGraphData);
		// 	this.createChart();
		// 	this.isloading = false;
		// });
	}

	getYPosition(point){
		switch (this.params?.name) {
		case "healthIndex":
			return this.healthIndexYPosition(point.healthIndex);
		break;
		case "riskIndex":
			return this.range?.findIndex(item => item.value === point.riskIndex);
		break;
		case "criticalIndex": 
			return this.range?.findIndex(item => item.value === point.criticalIndex);
		break;
		case "maintenanceIndex":
			return this.range?.findIndex(item => item.value === point.maintenanceIndex);
		break;
		default:
			return 0;
		break;
		}
	}

	healthIndexYPosition(y){
		switch (y) {
			case 1:
				return 2.25;	
				break;
			case 2:
				return 4.75;	
				break;
			case 3:
				return 6;	
				break;
			case 4:
				return 7.25;	
				break;
			case 5:
				return 9.5;	
				break;
			default:
				return 0;
				break;
		}
	}

	getStaticData(chartData: any) {
		this.staticChartData = [];
		let tempSource = JSON.parse(JSON.stringify(chartData));
		tempSource.forEach((point: any) => {
			let y = this.getYPosition(point);
			point.healthIndex = 'HI' + point.healthIndex;
			point.criticalIndex = 'CI' + point.criticalIndex;
			point.riskIndex = 'RI' + point.riskIndex;
			point.maintenanceIndex = 'MI' + point.maintenanceIndex;
			let arr = this.range?.map((o: any) => o.name);
			if (arr.indexOf(point[this.params?.name]) !== -1) {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.staticChartData.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: y
				})
			}
		});
	}
		
	getDynamicData(chartData: any) {
		this.dynamicChartData = [];
		let tempSource = JSON.parse(JSON.stringify(chartData));
		tempSource.forEach((point: any) => {
			let y = this.healthIndexYPosition(point.healthIndex);
			point.healthIndex = 'HI' + point.healthIndex;
			let arr = this.range?.map((o: any) => o.name);
			if (arr.indexOf(point[this.params?.name]) !== -1) {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.dynamicChartData.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: y
				})
			}
		});
	}
		
	getStaticForecastData(chartData: any) {
		this.staticForecastData = [];
		let tempSource = JSON.parse(JSON.stringify(chartData));
		tempSource.forEach((point: any) => {
			let y = this.healthIndexYPosition(point.healthIndex);
			point.healthIndex = 'HI' + point.healthIndex;
			let arr = this.range?.map((o: any) => o.name);
			if (arr.indexOf(point[this.params?.name]) !== -1) {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.staticForecastData.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: y
				})
			}
		});
	}

	healthScoreIndexPoint(y){
		if(y >= 0.5 && y < 4){
			return 1;
		}else if(y >= 4 && y < 5.5){
			return 2;
		}else if(y >= 5.5 && y < 6.5){
			return 3;
		}else if(y >= 6.5 && y < 8){
			return 4;
		}else if(y >= 8 && y < 11){
			return 5;
		}else{
			return 0
		}
	}
		
	getHealthScoreData(chartData: any) {
		this.healthScoreData = [];
		let tempSource = JSON.parse(JSON.stringify(chartData));
		tempSource.forEach((point: any) => {
			let y = point.chsData;
			point.healthIndex = 'HI' + this.healthScoreIndexPoint(point.chsData);
			let arr = this.range?.map((o: any) => o.name);
			if (arr.indexOf(point[this.params?.name]) !== -1) {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.healthScoreData.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: y
				})
			}
		});
        if(this.selectedForecastTypes.indexOf('short') !== -1 && this.shortHealthScore.length){
            this.prevHealthScore = this.healthScoreData;
            this.healthScoreData = this.healthScoreData.concat(this.shortHealthScore);
        }
	}

	getChartDurations() {
		// this.assetService.getChartDurations().subscribe((data: any) => {
		// 	this.durations = data;
		// })
	}

	onDurationChange(e: any) {
		this.dateRange.min = (new Date().setMonth(new Date().getMonth() - this.selectedDuration)).valueOf();
		this.isloading = true;
		this.fetchData(true);
	}

	// fetchForecastData() {
	// 	let payload = {
	// 	"shortTerm": this.forecastData.shortTerm,
	// 	"shortTermType": this.forecastData.shortTermType,
	// 	"midTerm": this.forecastData.midTerm,
	// 	"midTermType": this.forecastData.midTermType,
	// 	"longTerm": this.forecastData.longTerm,
	// 	"longTermType": this.forecastData.longTermType
	// 	}
	// 	this.assetService.getForecastChartData(payload, this.params?.assetId, this.params?.feeder).subscribe((chartData: any) => {
	// 		this.forecastFlag = true;
	// 		this.getShortTerm(chartData?.shortTerm);
	// 		this.getLongTerm(chartData?.midTerm);
	// 		this.getMidTerm(chartData?.longTerm);
	// 		this.createChart();
	// 	});
	// }

    fetchForecastData(payload: any) {
		// let payload = {
		// "shortTerm": this.forecastData.shortTerm,
		// "shortTermType": this.forecastData.shortTermType,
		// "midTerm": this.forecastData.midTerm,
		// "midTermType": this.forecastData.midTermType,
		// "longTerm": this.forecastData.longTerm,
		// "longTermType": this.forecastData.longTermType
		// }
		// this.assetService.getForecastChartData(payload, this.params?.assetId, this.params?.feeder).subscribe((chartData: any) => {
		// 	this.forecastFlag = true;
		// 	this.getShortTerm(chartData?.shortTerm);
		// 	this.getLongTerm(chartData?.midTerm);
		// 	this.getMidTerm(chartData?.longTerm);
		// 	this.createChart();
		// });
	}

	getShortTerm(chartData: any) {
		this.shortTermSeries = [];
		let tempSource = JSON.parse(JSON.stringify(chartData));
		tempSource.forEach((point: any) => {
            let y = this.healthIndexYPosition(point.healthIndex);
			point.healthIndex = 'HI' + point.healthIndex;
			let arr = this.range?.map((o: any) => o.name);
			if (arr.indexOf(point[this.params?.name]) !== -1) {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.shortTermSeries.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: y
				})
			}
		});

        this.shortHealthScore = [];
        let tempSource1 = JSON.parse(JSON.stringify(chartData));
        tempSource1.forEach((point: any) => {
            let y = point.HealthScore;
            point.healthIndex = 'HI' + this.healthScoreIndexPoint(point.HealthScore);
            let arr = this.range?.map((o: any) => o.name);
            if (arr.indexOf(point[this.params?.name]) !== -1) {
                let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
                this.shortHealthScore.push({
                    x: new Date((date).split('.')[0]).getTime(),
                    y: y
                })
            }
        });

        if(this.selectedDataTypes.includes('healthScore') && this.healthScoreData.length){
            this.healthScoreData = this.healthScoreData.concat(this.shortHealthScore);
        }
	}

	getMidTerm(chartData: any) {
		this.midTermSeries = [];
		let tempSource = JSON.parse(JSON.stringify(chartData));
		tempSource.forEach((point: any) => {
			let y = point.healthIndex;
			point.healthIndex = 'HI' + point.healthIndex;
			let arr = this.range?.map((o: any) => o.name);
			if (arr.indexOf(point[this.params?.name]) !== -1) {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.midTermSeries.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: y
				})
			}
		});
	}

	getLongTerm(chartData: any) {
		this.longTermSeries = [];
		let tempSource = JSON.parse(JSON.stringify(chartData));
		tempSource.forEach((point: any) => {
			let y = point.healthIndex;
			point.healthIndex = 'HI' + point.healthIndex;
			let arr = this.range?.map((o: any) => o.name);
			if (arr.indexOf(point[this.params?.name]) !== -1) {
				let date = formatDate(new Date(point.timestamp).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
				this.longTermSeries.push({
					x: new Date((date).split('.')[0]).getTime(),
					y: y
				})
			}
		});
	}

	// changeSeriesSelection(tag: string, e: any) {
	// 	let series = this.chartOptions.series;
	// 	let selectedSeries = series?.filter((item: any) => item.name == tag);
	// 	if (selectedSeries && selectedSeries[0]) {
	// 		if (e.target.checked) {
	// 			this.selectedForecastTypes.push(tag);
	// 			if(!this.forecastFlag)
	// 			this.fetchForecastData();
    //             if(tag = 'short') this.healthScoreData = this.prevHealthScore ? this.prevHealthScore.concat(this.shortHealthScore) : this.shortHealthScore;
	// 			this.createChart();  
	// 		}
	// 		else {
	// 			let index = this.selectedForecastTypes.indexOf(tag);
	// 			this.selectedForecastTypes.splice(index, 1);
    //             if(tag = 'short') this.healthScoreData = this.prevHealthScore;
	// 			this.createChart();
	// 		}
	// 	}
	// }

    changeSeriesSelection(tag: string, e: any) {
        let payload = {
            feederNumber: this.params?.feeder,
            assetId: this.params?.assetId,
        }
        this.predictionTypes.forEach(element => {
            payload[element.field] = element.value;
        });
        this.fetchForecastData(payload);
	}

	changeSelection(tag: string, e: any) {
		let series = this.chartOptions.series;
		let selectedSeries = series?.filter((item: any) => item.name == tag);
		if (e.target.checked) {
			this.selectedDataTypes.push(tag);
		}
		else {
			let index = this.selectedDataTypes.indexOf(tag);
			this.selectedDataTypes.splice(index, 1);
		}
		this.fetchStaticDynamicData();
		this.createChart();  
	}
}