import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrl: './pie-chart.component.scss'
})
export class PieChartComponent {
  @Input() data: any;
  @Input() target: string = '';
  @ViewChild('charts') public chartEl!: ElementRef;
  pieData: any = [];
  constructor() { }
  charts = [];
  colors = ['rgb(8, 170, 23)', 'rgb(248, 152, 67)', 'rgb(234, 47, 64)']
  ngOnInit(): void {
      //console.log(this.data)
      //need to implement for multiple assets
      this.data.forEach((item: any, index: number) => {
          this.pieData.push({
              name: item.name,
              y: item.count,
              color: this.colors[index]
          });
      });

      let el = this.target;
      setTimeout(() => {
          this.createChart(el);
      }, 1000)
  }

  createChart(el: any) {
      let e = document.createElement("div");
      Highcharts.chart(el, {
          chart: {
              height: 250,
          },
          title: {
              text: ''
          },
          plotOptions: {
              series: {
                  // general options for all series
                  
              },
              pie: {
                  // shared options for all variablepie series
                  allowPointSelect: false,
                  cursor: 'pointer',
                  dataLabels: {
                      enabled: true,
                      //format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                      formatter: function(): any {
                          if ((this as any).y > 0) {
                              return this.point.name + ': ' + Highcharts.numberFormat((this as any).point.percentage, 1) + ' %'
                            }                   
                        },
                      style: {
                          // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                      }
                  },
                  borderWidth: 0,
                  showInLegend: true
              }

          },
          legend: {
              labelFormat: '</b>{y}</b> {name}',
              enabled: true,
              align: 'right',
              verticalAlign: 'top',
              layout: 'vertical',
              symbolRadius: 0,
              itemMarginBottom: 5
          },
          exporting: {
              enabled: false
          },
          series: [{               
              type: 'pie',
              data: this.pieData,
              size: '80%'
          }]
      });
  }

}
