<div class=" noprint backdrop" *ngIf="isProcessing">
    <!-- <i class="fas fa-spinner fa-pulse fa-3x healthy" style="margin-top: 10%;
    margin-left: 47%;"></i> -->
    Loading...
  </div>
  
  <div class="no-preview-print print-banner  first-print-button" *ngIf="isIOS">
    <span class="pe-5 float-end py-2" (click)="print()" role="button"><i class="fa fa-print fa-2x"></i></span>
  </div>
  
  <!-- <span class="floating-print">
    <button class="btn text-white btn-lg btn-floating noprint d-block d-sm-block d-md-none bg-black" (click)="print()" role="button">
      <i class="fa fa-print text-white"></i>
    </button>
  </span> -->
  
  <div class="page-header" style="text-align: center">
    <!-- I'm The Header -->
    <br />
  </div>
  
  <div class="page-footer">
    <span> </span>
  </div>
  
  <table>
    <thead>
      <tr>
        <td>
          <!--place holder for the fixed-position header-->
          <!-- <div class="page-header-space"></div> -->
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div id="pdfTable" #pdfTable style="width:650px" class="pdfTable">
            <div class="container position-relative pdf-page" id="cover-page">
              <div class="header row">
                <img src="../../../../assets/images/reports/header.png" alt="" class="w-100">
              </div>
              <div class="title w-100 pe-5" style="margin-top:200px;height: 185px;">
                <img src="../../../../assets/images/newLogo/predict_dark.png" alt="" class="float-end" height="100">
              </div>
              <div class="text-center report-title">
                ASSET ASSESSMENT REPORT
              </div>
              <div class="details mt-3 w-100 p-3" style="font-size:18px">
                <div class="details-data w-100">
                  <span class="field-name">Customer:</span>
                  <span class="field-value">{{jsonData?.customer}}</span>
                </div>
                <div class="details-data">
                  <span class="field-name">Site:</span>
                  <span class="field-value">{{jsonData?.site}}</span>
                </div>
                <div class="details-data">
                  <span class="field-name">Report Duration:</span>
                  <span class="field-value">From {{reportDuration.from | date:'dd MMM yyyy'}} to {{reportDuration.to | date:'dd MMM yyyy'}} </span>
                </div>
                <div class="details-data">
                  <span class="field-name">Document Ref:</span>
                  <span class="field-value">{{jsonData?.documentRef}}</span>
                </div>
                <div class="details-data">
                  <span class="field-name">Confidentiality details:</span>
                  <span class="field-value">{{jsonData?.confidentialDetails}}</span>
                </div>
              </div>
              <div class="footer w-100 mt-5">
                <img src="../../../../assets/images/lntlogo.png" alt="" class="float-end" height="55">
              </div>
            </div>
  
            <div class="container pdf-page">
              <div class="section-header content w-100">
                <h2>Contents</h2>
              </div>
              <div class="section-content contents-titles w-100 mt-5">
                <ol class="toc-list p-0" role="list">
                  <li>
                    <a href="#Introduction">
                      <span class="title"><span class="title-text"> 1. &nbsp;Introduction</span></span>
                    </a>
                  </li>
                  <li>
                    <a href="#Health-Summary">
                      <span class="title"><span class="title-text"> 2. &nbsp;Health Summary</span></span>
                    </a>
                  </li>
                  <li>
                    <a href="#Maintenance-Summary">
                      <span class="title"><span class="title-text"> 3. &nbsp;Maintenance Summary</span></span>
                    </a>
                  </li>
                  <li>
                    <a href="#Promise-Basics">
                      <span class="title"><span class="title-text"> 4. &nbsp;Sub-Assets Summary</span></span>
                    </a>
                  </li>
                  <li>
                    <a href="#Promise-Basics">
                      <span class="title"><span class="title-text"> 5. &nbsp;Life cycle Summary</span></span>
                    </a>
                  </li>
                  <li>
                    <a href="#Promise-Basics">
                      <span class="title"><span class="title-text"> 6. &nbsp;Alarm Summary</span></span>
                    </a>
                  </li>
                  <li>
                    <a href="#Promise-Basics">
                      <span class="title"><span class="title-text"> 7. &nbsp;Recommendations / Remedial
                          actions</span></span>
                    </a>
                  </li>
                </ol>
              </div>
            </div>
  
  
  
  
  
            <div class="container pdf-page">
              <div class="section-header">
                <span class="title-text" id="Introduction"> 1. &nbsp;Introduction</span>
              </div>
              <div class="section-content">
                <p>
                  This report provides details of health assessment done by Smartcomm predict algorithm for the selected assets
                  based on
                  the data collected between {{reportDuration.from | date:'dd MMM yyyy'}} and {{reportDuration.to | date:'dd MMM yyyy'}}.
                </p>
                <p *ngIf="jsonData?.introduction?.assets?.length > 1">
                  Total Assets: {{jsonData.introduction.assets.length}}
                </p>
                <p>
                  Total Subassets: {{jsonData?.introduction?.totalSubAssets}}
                </p>
              </div>
  
              <div class="section-header">
                <span class="title-text" id="Health-Summary"> 2. Health Summary</span>
              </div>
              <app-donut-chart [target]="'donutChart'" [data]="jsonData?.healthSummary?.barGraph"></app-donut-chart>
              <div class="section-content">
                <p>
                    Following is the current health status as computed by the algorithm for the selected asset.
                </p>
                <div>
                  <div *ngFor="let item of jsonData?.healthSummary?.assetGraphs;let i=index">
                    <!-- <div style="height:750px" [ngClass]="i>0? 'pdf-page':''"> -->
                    <!-- <div [ngClass]="i>0? 'pdf-page':''"> -->
                    <div>
                      <p>
                        <b><u>Asset{{i+1}}:{{item?.assetName}}</u></b>
                      </p>
                      <p>
                        <span class="title-text">Health Index:</span>
                        <span class="ms-3">{{item?.healthIndex}}</span>
                      </p>
                      <p>
                        <span class="title-text">Critical Index:</span>
                        <span class="ms-3">{{item?.criticalIndex}}</span>
                      </p>
                      <p>
                        <span class="title-text">Risk Index:</span>
                        <span class="ms-3">{{item?.riskIndex}}</span>
                      </p>
                      <p>
                        <span class="title-text">Maintenance Index:</span>
                        <span class="ms-3">{{item?.maintenanceIndex}}</span>
                      </p>
                      <p>
                        <span class="title-text">Last maintenance date:</span>
                        <span class="ms-3">{{item?.lastMaintenanceDate ? (item.lastMaintenanceDate  | date:'dd MMM yyyy') : 'NA' }}</span>
                      </p>
                      <p>
                        <span class="title-text">Next Suggested maintenance date:</span>
                        <span class="ms-3">{{item?.suggestedNextMaintenanceDate ? (item.suggestedNextMaintenanceDate  | date:'dd MMM yyyy') : 'NA' }}</span>
                      </p>
                      <p>
                        <u>KPI variation during the selected time period: </u>
                      </p>
                    </div>
  
                    <!-- <div id="chart1{{i}}" #chart1 style="height: 1960px;"> -->
                    <div id="chart1{{i}}" #chart1>
                      <p>
                        <span class="title-text">Health Index</span>
                      </p>
                      <div>
                        <app-linear-gradient-chart [target]="'asset-report-health-'+i" [data]="chartData?.healthIndex"
                          [params]="{assetId: 0, feeder: '', name: 'healthIndex'}" [reportData]="item?.chartData"
                          [reportDuration]="reportDuration"></app-linear-gradient-chart>
                      </div>
                      <p>
                        <span class="title-text">Criticality Index</span>
                      </p>
                      <div>
                        <app-linear-gradient-chart [target]="'asset-report-critical-'+i" [data]="chartData?.crticalIndex"
                          [params]="{assetId: 0, feeder: '', name: 'criticalIndex'}" [reportData]="item?.chartData"
                          [reportDuration]="reportDuration"></app-linear-gradient-chart>
                      </div>
                      <p>
                        <span class="title-text">Risk Index</span>
                      </p>
                      <div>
                        <app-linear-gradient-chart [target]="'asset-report-risk-'+i" [data]="chartData?.riskIndex"
                          [params]="{assetId: 0, feeder: '', name: 'riskIndex'}" [reportData]="item?.chartData"
                          [reportDuration]="reportDuration"></app-linear-gradient-chart>
                      </div>
                      <p>
                        <span class="title-text">Maintenance Index</span>
                      </p>
                      <div>
                        <app-linear-gradient-chart [target]="'asset-report-maintenanceIndex-'+i"
                          [data]="chartData?.maintenanceIndex"
                          [params]="{assetId: 0, feeder: '', name: 'maintenanceIndex'}" [reportData]="item?.chartData"
                          [reportDuration]="reportDuration"></app-linear-gradient-chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="section-header">
                <span class="title-text" id="Health-Summary"> 3. Maintenance Summary</span>
              </div>
              <div class="section-content">
                <p>
                  Followings are the maintenance activities carried out during the selected period.
                </p>
                <p class="title-text pt-2">
                  Work permit summary
                </p>
                <div class="chart-placeholder">
                  <app-stacked-bar-chart [target]="'statusBasedData'"
                    [reportData]="jsonData?.maintenanceSummary?.statusBasedData"></app-stacked-bar-chart>
                </div>
                <div class="chart-placeholder">
                  <app-stacked-bar-chart [target]="'priorityBasedData'"
                    [reportData]="jsonData?.maintenanceSummary?.priorityBasedData"></app-stacked-bar-chart>
                </div>
                <p class="title-text pt-2">
                  List of completed Task.
                </p>
                <div class="text-center m-4 critical" *ngIf="jsonData?.maintenanceSummary?.completedTasks?.length == 0">None</div>
                <div class="tabular-data">
                  <app-report-table [data]="jsonData?.maintenanceSummary?.completedTasks"></app-report-table>
                </div>
                <!-- <p class="title-text">
                  Engineer wise task details:
                </p>
                <p>
                  Following chart represents the engineer and asset wise permit work performed in selected period.
                </p>
                <div class="calender-view">
                  <app-report-calender></app-report-calender>
                </div> -->
  
              </div>
  
              <div class="section-header">
                <span class="title-text" id="Health-Summary">4. Sub-Assets Summary</span>
              </div>
              <div class="section-content">
                <p>
                  Following is the summary of risk status of sub assets (feeders) as computed by the algorithm considering
                  the
                  health index and criticality index for the selected period.
                </p>
                <ng-container *ngFor="let item of jsonData?.subAssetSummary;let i=index">
                  <div>
                    <p class="mt-3 fw-bold">
                      <u>{{item?.asset}}</u>
                    </p>
                    <ng-container>
                      <div class="sub-asset-matrix" style="justify-content: center;display: flex;">
                        <div class="p-3" #matrix>
                          <div style="height:fit-content" *ngIf="item?.subAssetMatrixData?.length">
                            <app-subasset-matrix [subAssetRiskMatrix]="item?.subAssetMatrixData" id="matrix{{i}}"
                              style="width:62%;" [showY]="false"></app-subasset-matrix>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!(item?.subAssetMatrixData?.length)">
                      <p class="text-center m-4 critical"> None</p>
                    </ng-container>
                    <ng-container *ngIf="item?.statistics?.length">
                      <div class="plain-tabular-data">
                        <app-plain-table [data]="item?.statistics"></app-plain-table>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
              <div class="section-header">
                <span class="title-text" id="Health-Summary">5. Component Life cycle Summary</span>
              </div>
              <div class="section-content">
                <p>
                  Follwing chart shows distribution of “under active life cycle” , “Under notice “ and “obsoleted”
                  components for
                  the selected asset.
                </p>
                <div class="pie-chart">
                  <div *ngFor="let item of jsonData?.componentLifeCycleSummary?.obsolesceneStatus;let i=index">
                    <p>
                      <b><u>{{item?.asset}}</u></b>
                    </p>
                    <app-pie-chart [target]="'pieChart'+i" [data]="item.components"></app-pie-chart>
                  </div>
  
                </div>
                <p class="title-text pt-2">
                  <u>List of Component – Obsoleted</u>
                </p>
                <div class="text-center m-4 critical" *ngIf="jsonData?.componentLifeCycleSummary?.obsoleteComponents?.length == 0">None</div>
                <div class="tabular-data">
                  <app-report-table [data]="jsonData?.componentLifeCycleSummary?.obsoleteComponents"></app-report-table>
                </div>
                <p class="title-text pt-2">
                  <u>List of Component – Under Obsolescence Notice</u>
                </p>
                <div class="text-center m-4 critical" *ngIf="jsonData?.componentLifeCycleSummary?.underObsolesceneNotice?.length == 0">None</div>
                <div class="tabular-data">
                  <app-report-table
                    [data]="jsonData?.componentLifeCycleSummary?.underObsolesceneNotice"></app-report-table>
                </div>
                <p class="title-text pt-2">
                  <u>Assets warranty and service contract status</u>
                </p>
                <div class="text-center m-4 critical" *ngIf="jsonData?.componentLifeCycleSummary?.warrantyContractStatus?.length == 0">None</div>
                <div class="plain-tabular-data">
                  <app-plain-table [data]="jsonData?.componentLifeCycleSummary?.warrantyContractStatus"></app-plain-table>
                </div>
              </div>
  
              <div class="section-header">
                <span class="title-text" id="Health-Summary">6. Alarm Summary</span>
              </div>
              <div class="section-content">
                <p>
                  During the selected period, following alarms have been generated related to selected assets.
                </p>
                <p class="title-text pt-2">
                  <u> Asset wise alarm/event count </u>
                </p>
                <div class="chart-placeholder">
                  <app-stacked-bar-chart [target]="'assetWiseCount'"
                    [reportData]="jsonData?.alarmSummary?.assetWiseCount"></app-stacked-bar-chart>
  
                </div>
                <p class="title-text pt-2">
                  <u> Component Type wise alarm/event count </u>
                </p>
                <div class="chart-placeholder">
                  <app-stacked-bar-chart [target]="'componentWiseCount'"
                    [reportData]="jsonData?.alarmSummary?.componentWiseCount"></app-stacked-bar-chart>
  
                </div>
                <p class="title-text pt-2">
                  <u> Alarm type wise alarm/event count </u>
                </p>
                <div class="chart-placeholder">
                  <app-stacked-bar-chart [target]="'alarmTypeWiseCount'"
                    [reportData]="jsonData?.alarmSummary?.alarmTypeWiseCount"></app-stacked-bar-chart>
  
                </div>
              </div>
  
              <div class="section-header">
                <span class="title-text" id="Health-Summary">7. Recommendations / Remedial actions.</span>
              </div>
              <div class="section-content">
                <div class="text-center m-4 critical" *ngIf="jsonData?.recommendations?.length == 0">None</div>
                <div class="tabular-data">
                  <app-report-table [data]="jsonData?.recommendations"></app-report-table>
                </div>
              </div>
  
  
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <!--place holder for the fixed-position footer-->
          <div class="page-footer-space"></div>
        </td>
      </tr>
    </tfoot>
  
  </table>