import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-assessment-report',
  templateUrl: './assessment-report.component.html',
  styleUrl: './assessment-report.component.scss'
})
export class AssessmentReportComponent {

  @Input() jsonData: any;
  @Input() documentTitle: any;
  chartData = {
      "healthIndex": {
          "indexRange": {
              "HI1": 1,
              "HI2": 2,
              "HI3": 3,
              "HI4": 4,
              "HI5": 5
          },
          "indexValue": "",
          "indexStatus": ""
      },
      "crticalIndex": {
          "indexRange": {
              "CI1": 1,
              "CI2": 2,
              "CI3": 3,
              "CI4": 4
          },
          "indexValue": "",
          "indexStatus": ""
      },
      "maintenanceIndex": {
          "indexRange": {
              "MI1": 1,
              "MI2": 2,
              "MI3": 3,
              "MI4": 4
          },
          "indexValue": "",
          "indexStatus": ""
      },
      "riskIndex": {
          "indexRange": {
              "RI11": 11,
              "RI12": 12,
              "RI13": 13,
              "RI14": 14,
              "RI21": 21,
              "RI22": 22,
              "RI23": 23,
              "RI24": 24,
              "RI31": 31,
              "RI32": 32,
              "RI33": 33,
              "RI34": 34,
              "RI41": 41,
              "RI42": 42,
              "RI43": 43,
              "RI44": 44,
              "RI51": 51,
              "RI52": 52,
              "RI53": 53,
              "RI54": 54
          },
          "indexValue": "",
          "indexStatus": ""
      }
  }

  pdfDoc: any;
  assetCount: number = 1;
  isProcessing: boolean = false;
  subassetMatrix: any;
  @Input() reportDuration!: any;
  isDesktopDevice: boolean = true;
  isIOS: boolean = false;


  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
      this.epicFunction();
      this.isIOS = this.iOS();
      this.isProcessing = true;
  }

  epicFunction() {
      this.isDesktopDevice = this.deviceService.isDesktop();
  }

  iOS() {
      return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
      ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;
  @ViewChild('chart2', { static: false }) chart2!: ElementRef;


  @ViewChildren('matrix') matrixComponents!: QueryList<ElementRef>

  ngAfterViewInit() {
      let backdrop = document.getElementsByClassName('paddingSlim') as HTMLCollectionOf<Element>;
      backdrop[0]?.classList?.add('centerAligned');
      backdrop[0]?.classList?.remove('paddingSlim');
      let self = this;
      self.prepareDoc();
  }

  prepareDoc() {
      // this.subassetMatrix = new Array(this.matrixComponents.length);

      // this.matrixComponents.forEach((child: any, i: number) => {
      //   let el = child.nativeElement;
      //   let id = 'matrix' + i;
      //   let chart = document.getElementById(id) as HTMLElement;
      //   if (chart) {
      //     this.subassetMatrix[i] = chart.innerHTML;
      //     html2canvas(child.nativeElement).then(canvas => {
      //       let image = canvas.toDataURL();
      //       chart.innerHTML = `<span style='rotate: -180deg;
      //       writing-mode: vertical-rl;
      //       height: 182px;
      //       font-weight: 600;
      //       left: 0px;
      //       position: relative;'>Index Criticality</span><img src= ${image}  width='400'>`;
      //     })
      //   }
      // })
      let self = this;
      setTimeout(() => {
          let backdrop = document.getElementsByClassName('content-area') as HTMLCollectionOf<Element>;
          //   if (!this.isDesktopDevice)
          //     backdrop[0]?.classList?.add('zoomOut');
          self.isProcessing = false;
          //   if(!this.isIOS) 
          this.loadPagedJsPolyfill();
      }, 8000);
  }


  loadPagedJsPolyfill() {
      const node = document.createElement('script');
      node.src = '../../../../../../assets/js/paged.pollyfil.js';
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
      setTimeout(() => {
          this.addPrintButton();
      }, 2000);
  }

  print() {
      this.isProcessing = false;
      let self = this;
      setTimeout(() => {
          document.title = self.documentTitle;
          window.print();
      }, 5000)

  }

  addPrintButton() {
      document.title = this.documentTitle;
      let div = document.getElementsByClassName('pagedjs_pages') as HTMLCollectionOf<Element>;
      let el = `<div class="noprint print-banner  print-button desktop-only" style="
  position: absolute;
  right: 0;
  width: 100%;
">
  <span class="pe-5 float-end py-2" role="button"><i class="fa fa-print fa-2x"></i></span>
</div>
`
      let element = document.createElement('div');
      element.classList.add('noprint');
      element.style.height = '57px';
      element.innerHTML = el;
      div[0]?.prepend(element);
      let printButtonEl = document.getElementsByClassName('print-button') as HTMLCollectionOf<Element>;
      printButtonEl[0]?.addEventListener('click', (e) => {
          window.print();
      });
  }

}

