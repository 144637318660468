import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DigitalLogBookService } from '../../service/digital-log-book.service';

@Component({
  selector: 'app-show-reports',
  templateUrl: './show-reports.component.html',
  styleUrl: './show-reports.component.scss'
})
export class ShowReportsComponent {
  private subscription: Subscription = new Subscription();
  reportId!: number;
  reportData: any;
  documentTitle: any;
  @Input() dlbReports: any;
  @Input() reportDuration: {from?: Date, to?: Date;} = {};

  constructor(private dlbService: DigitalLogBookService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.reportId = params['id'];
      if(!this.reportData) 
        this.fetchReportData();
    });
  }

  fetchReportData() {
    this.subscription.add(
      this.dlbService.getReportJSON(this.reportId).subscribe((data: any) => {
        this.reportData = data;
        this.documentTitle = data.name +'-'+ data.dateTime;
        if(this.reportData?.reportType == 'Assessment') {
          let duration = data?.assessmentJson?.reportDuration;
          this.reportDuration['from'] = duration.split(' ')[1];
          this.reportDuration.to = duration.split(' ')[4];
        }
       
      }))
  }
}
