import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrl: './donut-chart.component.scss'
})
export class DonutChartComponent {

  @Input() data: any;
  @Input() target: string = '';
  @ViewChild('charts') public chartEl!: ElementRef;
  pieData: any = [];
  constructor() { }
  charts = [];
  colors = ['rgb(8, 170, 23)', 'rgb(157, 213, 148)', 'rgb(243, 196, 44)', 'rgb(250, 118, 0)', 'rgb(234, 47, 64)']

  ngOnInit(): void {

    this.data?.data.forEach((item: any, index: number) => {
      this.pieData.push({
        name: item.name,
        y: item.count,
        color: this.colors[index]
      });
    });
    let el = this.target;
    setTimeout(() => {
      this.createChart(el);
    }, 1000)
  }

  createChart(el: any) {
    let e = document.createElement("div");
    Highcharts.chart(el, {
      chart: {
        spacingLeft: 100
    },
      title: {
        text: '<b><span style="font-size: 46px;">'+ this.data?.totalAssets +'</span></b><br>Total assets' ,
        verticalAlign: 'middle',
        x: -72,
        y: 35,
        style: {
          font: '16px Source Sans Pro',
        },
      },
      plotOptions: {        
        series: {
          // general options for all series
        },
        pie: {
          // shared options for all variablepie series
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          },
          borderWidth: 0,
          showInLegend: true
        }

      },
      legend: {
        labelFormat: '</b>{y}</b> {name}',
        enabled: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        symbolRadius: 0,
        itemMarginBottom: 5
      },
      exporting: {
        enabled: false
      },
      series: [{
        // specific options for this series instance
        type: 'pie',
        data: this.pieData,
        size: '70%',
        innerSize: '80%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }
      }]
    });
  }
}
